const prodConfig = {
  apiKey: 'AIzaSyDb-YW6tf-MWsNmMzCGcDR2Z-5MBXsqCv0',
  authDomain: 'areweplayingdnd.firebaseapp.com"',
  databaseURL: 'https://areweplayingdnd.firebaseio.com',
  projectId: 'areweplayingdnd',
  messagingSenderId: "88964300310"
};

const devConfig = {
  apiKey: 'AIzaSyDb-YW6tf-MWsNmMzCGcDR2Z-5MBXsqCv0',
  authDomain: 'areweplayingdnd.firebaseapp.com"',
  databaseURL: 'https://areweplayingdnd.firebaseio.com',
  projectId: 'areweplayingdnd',
  messagingSenderId: "88964300310"
};

const config = devConfig;

class Firebase {
  constructor(app) {
    if (!app.apps.length) {      
      app.initializeApp(config);
    }

    this.db = app.database();
    this.auth = app.auth();    
    this.app = app;
    this.messaging = app.messaging();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  doCreateUser = (id, username, email) =>
    this.db.ref(`users/${id}`).set({
      username,
      email,
    });

  // *** Playgroup API ***

  doCreatePlaygroup = (name, id, creatorUser) => {
    // Add playgroup with creator ref.
    const uid = creatorUser.uid;    
    let members = {};
    members[uid] = {
      role: 'creator',      
      hasConfirmed: false,      
      fcmTokens: {}
    };
    this.db.ref(`playgroups/${id}`).set({
      name,
      members
    });

    // Inverse of above - add playgroup ref to creator.
    this.db.ref(`users/${uid}/playgroups/${id}`).set({
      role: 'creator',      
    });
  }

  initializeMessaging = (user) => {
    const messaging = this.app.messaging();
    messaging
      .requestPermission()
      .then(() => {
        console.log('Permission received.');
        return messaging.getToken();
      })
      .then(token => {
        // console.log(token);                
        // Save token with user.
        return this.saveFcmToken(token, user);
      })
      .catch(error => {
        console.log('Error occurred.', error);
      });
  }

  saveFcmToken(token, user) {    
    this.db.ref(`/users/${user.uid}/fcmTokens/${token}`).set(1);
  }
}

let firebase;

function getFirebase(app, auth, database, messaging) {
  if (firebase) {
    return firebase;
  }

  firebase = new Firebase(app, auth, database, messaging);

  return firebase;
}

export default getFirebase;
