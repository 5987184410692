import React, { Component } from 'react';
import PlaygroupContext from '../Playgroup/PlaygroupContext';

const confirmationStatusLabel = status => {
  return status ? 'Confirmed' : 'Not confirmed'
}

const MemberConfirmationStatus = props => {
  if (props.member.username) {
    return (
      <li>{props.member.username}: {confirmationStatusLabel(props.member.hasConfirmed)}</li>
    )
  }

  return null;
}

const MemberConfirmationStatuses = props => (
  <PlaygroupContext.Consumer>
    {playgroupContext => 
      playgroupContext.playgroup ? 
      <ul>
        {Object.keys(playgroupContext.playgroup.members).map(uid => (
          <MemberConfirmationStatus member={playgroupContext.playgroup.members[uid]}/>
        ))}
      </ul> : null
    }
  </PlaygroupContext.Consumer>
)

export default MemberConfirmationStatuses;