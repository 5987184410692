import React from 'react';
import withNotifications from './withNotifications';
import NotificationsContext from './NotificationsContext'

const NotificationsList = (props) => (
  <NotificationsContext.Consumer>
    {
      messages => {        
        return (
          messages ? messages.map((message, index) => (
            <Notification message={message} />
          )) : null
        )
      }
    }
  </NotificationsContext.Consumer>
);

const Notification = props => (
  <div className="notification">{props.message}</div>
);

export default withNotifications(NotificationsList);