import React from 'react';
import Layout from '../components/layout';
import { Router } from "@reach/router"
import PlaygroupContext from '../components/Playgroup/PlaygroupContext';
import withPlaygroup from '../components/Playgroup/withPlaygroup';
import withAuthorization from '../components/Session/withAuthorization';
import PlaygroupDateWidget from '../components/PlaygroupDateWidget';
import MemberConfirmationStatuses from '../components/MemberConfirmationStatuses';

const PlaygroupBase = props => (
  <PlaygroupContext.Consumer>
    {playgroupContext => 
      playgroupContext.playgroup ? <React.Fragment>
        <h2>{playgroupContext.playgroup.name}</h2>
        <PlaygroupDateWidget />
        <MemberConfirmationStatuses />
      </React.Fragment> : null
    }
  </PlaygroupContext.Consumer>
)

const Playgroup = withPlaygroup(PlaygroupBase);
const authCondition = authUser => !!authUser;
const PlaygroupWithAuthentication = withAuthorization(authCondition)(Playgroup);

export default () => (
  <Layout>
    <Router>
      <PlaygroupWithAuthentication path="/playgroup/:playgroupId"/>
    </Router>    
  </Layout>
);
