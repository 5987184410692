import React from 'react';
import { navigate } from 'gatsby';

import * as routes from '../../constants/routes';
import { withFirebase } from '../Firebase/FirebaseContext';
import PlaygroupContext from './PlaygroupContext';

const withPlaygroup = Component => {
  class WithPlaygroup extends React.Component {
    constructor(props) {
      super(props);      

      this.state = {
        initFirebase: false,
        playgroup: null,
        updatePlaygroup: playgroup => this.updatePlaygroup(playgroup)
      };
    }

    updatePlaygroup = playgroup => {      
      this.setState(prevState => ({
        ...prevState,
        playgroup
      }));

      this.props.firebase.db
        .ref(`/playgroups/${this.props.playgroupId}`)
        .update(playgroup);
    }

    getPlayGroup = () => {
      return this.props.firebase.db
        .ref(`/playgroups/${this.props.playgroupId}`)
        .on('value', snapshot => {          
          this.setState({
            playgroup: snapshot.val()
          })
        });
    }

    firebaseInit = () => {
      if (this.props.firebase && !this.state.initFirebase) {
        this.props.firebase.auth.onAuthStateChanged(() => {
          this.getPlayGroup();
        });

        this.setState({ initFirebase: true });
      }
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    render() {    
      return (
        <PlaygroupContext.Provider value={this.state}>
          <Component {...this.props} />
        </PlaygroupContext.Provider>
      );
    }
  }

  return withFirebase(WithPlaygroup);
};

export default withPlaygroup;
