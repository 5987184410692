import React from 'react';

import AuthUserContext from './AuthUserContext';
import { withFirebase } from '../Firebase/FirebaseContext';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        initFirebase: false,
        authUser: null,
      };
    }

    firebaseInit = () => {
      const firebase = this.props.firebase;

      // Need to load the version of the user that we store in Firebase /users.
      if (firebase && !this.state.initFirebase) {
        firebase.auth.onAuthStateChanged(authUser => {
          if (authUser) {
            firebase.db.ref(`/users/${authUser.uid}`).on('value', snapshot => {
              // Add uid back into the object.
              let userFromFirebase = snapshot.val();              
              userFromFirebase.uid = authUser.uid;

              console.log(userFromFirebase);

              this.setState(() => ({
                authUser: userFromFirebase,
                initFirebase: true 
              }))
            })
          }
          else {
            this.setState(() => ({
              authUser: null,
              initFirebase: true,
            }));
          }
        });
      }
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    render() {
      const { authUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
