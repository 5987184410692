import React from 'react';
import { withFirebase } from '../Firebase/FirebaseContext';
import NotificationsContext from './NotificationsContext';

const withNotifications = Component => {
  class WithNotifications extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        initFirebase: false,
        messages: [],
      };    
    }    

    initMessaging() {      
      if (this.props.firebase && !this.state.initFirebase) {
        this.props.firebase.auth.onAuthStateChanged(authUser => {
          if (authUser) {
            this.props.firebase.initializeMessaging(authUser);
          }
        });

        this.props.firebase.messaging.onMessage(payload => {
          this.setState((state, props) => {            
            return {              
              messages: [
                ...this.state.messages,
                payload.notification.body
              ]
            }
          });
        });

        this.setState({ initFirebase: true });
      }
    };

    componentDidMount() {
      this.initMessaging();
    }

    componentDidUpdate() {
      this.initMessaging();
    }

    render() {      
      const messages = this.state.messages;

      return (
        <NotificationsContext.Provider value={messages}>
          <Component {...this.props} />
        </NotificationsContext.Provider>
      );
    }
  }

  return withFirebase(WithNotifications);
}

export default withNotifications;