import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import moment from 'moment';
import PlaygroupContext from '../Playgroup/PlaygroupContext';

import "react-datepicker/dist/react-datepicker.css";

class PlaygroupDateWidgetInner extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    let playgroup = { ...this.props.playgroup }
    playgroup.date = moment(date).format();

    this.props.updatePlaygroup(playgroup);
  }

  render() {    
    return (
      <React.Fragment>
        <DatePicker
          selected={moment(this.props.playgroup.date) || moment()}
          onChange={this.handleChange}            
        />
        <div>{this.props.playgroup.date ? this.props.playgroup.date : moment().format()}</div>
      </React.Fragment>
    );
  }
}

const PlaygroupDateWidget = props => (
  <PlaygroupContext.Consumer>
    {playgroupContext => 
      playgroupContext.playgroup ? 
      <PlaygroupDateWidgetInner
        {...props}
        playgroup={playgroupContext.playgroup}
        updatePlaygroup={playgroupContext.updatePlaygroup}
      /> : null
    }
  </PlaygroupContext.Consumer>
)

export default PlaygroupDateWidget;